<template>
	<div id="library-wrap">
		<Header :navbg='true'></Header>
		<div class="section bg">
			<div class="intr">
				小院图书馆参考国内外社区图书馆发展，结合国内全民阅读的推广政策，线上开发免费借阅平台，线下以社区为核心快速布点小院图书馆，打造居民活动范围1km内的图书借阅空间场所，做全民阅读的推广者，为建设书香城市贡献自己的力量。小院图书馆提供包含“海量图书·免费借阅”服务、“心愿单”功能、共享书架、共建书屋等内容与板块。同时打造复合型阅读空间，提供包含星巴克咖啡服务、共享办公、文化沙龙等优质服务，从而传递健康、绿色、优质的生活方式。
			</div>
			<div class="content">
				<div class="item down1">
					<i>海量图书 免费借阅</i>
					<img src="../../assets/images/new/down1.png" >
				</div>
				<div class="item down2">
					<i>您的心愿单</i>
					<img src="../../assets/images/new/down2.png" >
				</div>
				<div class="item down3">
					<i>共享书架</i>
					<img src="../../assets/images/new/down3.png" >
				</div>
			</div>
			<div class="btn">立即免费借阅</div>
		</div>
		<div class="section" v-for="(item,index) in dataList" :key="index" :style="{backgroundImage:`url(${item.image})`,backgroundSize:'cover'}">
			<div class="title">{{item.title}}</div>
			<div class="intr">{{item.intr}}</div>
			<div class="btn">{{item.btn}}</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header from '@/components/head2022'
	import Footer from '@/components/foot2022'
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				dataList:[
					{
						title:'海量图书 免费借阅',
						intr:'“海量图书 免费借阅”，这不仅仅是一个宣传口号，更是小院图书馆自开设以来一直在认真努力推行的事业，我们希望通过自己的一份微薄力量能够让更多大众喜欢阅读，培养大家的阅读习惯，小院图书馆也立志成为全民阅读的推广者。小院图书馆通过全网图书资源以及与新华书店、中国建筑出版社等多家合作实现图书的海量存储，并且所有图书免费借阅，我们积极践行全民阅读，为建设书香之城贡献力量。',
						btn:'立即免费借阅',
						image: require("../../assets/images/new/librarybg2.png")
					},
					{
						title:'你的心愿单服务',
						intr:'为了满足更多读者对阅读的需要，小院图书馆设置心愿单功能，即图书馆内没有的图书，读者如果想要借阅可在小程序上提交心愿单，小院图书馆将有专属团队在72小时内将读者心愿单图书进行采购到店并联系读者取书。而图书馆内没有的图书指的是单个图书馆内当下没有读者想要借阅的图书或读者想要借阅的图书正在被借阅。以上情况下只要读者提交心愿单，我们就会在72小时之内将图书采购到店，供读者阅读。',
						btn:'立即体验',
						image: require("../../assets/images/new/librarybg3.png")
					},
					{
						title:'共享书架',
						intr:'共享书架是小院图书馆业务核心服务内容，通过对阅读需求 的收集整理，为喜爱阅读的个人和家庭 准备的无限期图书借阅服务，让定量的家庭书架/个人书架成为无限阅读的共享书架。我们将推出围绕社区图书馆周边3KM社区的线上线下结合社区书屋站点，周边社区居民可在家门口享受图书的借阅服务，让阅读，尽在眼前。共享书架服务，即图书借阅会员服务，购买此服务即可享受多本图书的无限制时间借阅，会员可挑选自己喜欢的单本/多本图书进行一次性借阅，借阅时间不限，看完归还即可继续借阅喜爱的图书；会员也可选择适合自己或具有展示属性的图书进行借阅，用于充实个人书架，展示功能，借阅图书不限制归还时间，如归还，可继续借阅新的图书。',
						btn:'详情联系我们',
						image: require("../../assets/images/new/librarybg4.png")
					},
				]
			}
		},
		methods: {
		}
	}
</script>

<style lang="scss">
	#library-wrap{
		width: 100%;
		.title{
			font-size: 26px;
			font-weight: bold;
			line-height:26px;
			text-align: center;
		}
		.subtitle{
			font-size: 16px;
			line-height: 16px;
		}
		.intr{
			padding: 0 27px;
			font-size: 12px;
			line-height: 20px;
			font-weight: bold;
		}
		.btn {
			font-size: 12px;
			line-height: 32px;
			text-align: center;
			cursor: pointer;
			width:247px;
			height:32px;
			border: 1px solid #fff;
			border-radius:16px;
			margin: 0 auto;
		}
		.section {
			padding: 106px 0 112px;
			box-sizing: border-box;
			width: 100%;
			height: 100vh;
			color: #FFFFFF;
			background-repeat: no-repeat;
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			&.bg{
				background: url('../../assets/images/new/librarybg1.png');
			}
			.content{
				font-size: 16px;
				font-weight: bold;
				line-height: 16px;
				color: #9E9E9E;
				text-align: center;
				.item{
					display: flex;
					flex-direction: column;
					margin: 0 auto;
					cursor: pointer;
					margin: 15px 0;
					&.down1{
						width: 151px;
					}
					&.down2{
						width: 92px;
						margin-left:30px;
					}
					&.down3{
						width: 75px;
						margin-left:38px;
					}
					&:hover{
						color: #FFFFFF;
						img {
						    filter:brightness(300%);
						}
					}
					i{
						margin-bottom: 6px;
					}
				}
			}
		}
	}
</style>